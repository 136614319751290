@tailwind base;
@tailwind components;
/* this file is for public and private/admin pages */
a, .button-link {
  @apply text-blue-primary underline;
  transition: color 0.25s ease; }
  a:hover, a:active, .button-link:hover, .button-link:active {
    @apply text-blue-600; }

h1, h2, h3, h4, h5, h6 {
  @apply leading-tight font-bold; }

h1 {
  @apply text-3xl;
  margin: 0.67rem 0; }

h2 {
  @apply text-2xl;
  margin: 0.83rem 0; }

h3 {
  @apply text-xl;
  margin: 1rem 0; }

h4 {
  margin: 1.33rem 0; }

h5 {
  @apply text-sm;
  margin: 1.67rem 0; }

h6 {
  @apply text-xs;
  margin: 2.33rem 0; }

h1 a, h2 a, h3 a, h4 a {
  @apply no-underline text-inherit; }
  h1 a:hover, h1 a:active, h2 a:hover, h2 a:active, h3 a:hover, h3 a:active, h4 a:hover, h4 a:active {
    @apply underline text-inherit; }

p {
  @apply my-4; }

ul, ol {
  @apply pl-10; }

ul {
  @apply list-disc; }

ol {
  @apply list-decimal; }

address {
  @apply not-italic; }

td {
  @apply align-top; }

html {
  @apply font-sans leading-normal text-black; }

h1, h2, h3, h4, h5, h6 {
  @apply leading-tight; }

.alert {
  @apply flex justify-between w-11/12 mt-0 mx-auto mb-1 p-2 bg-gray-200 border-l-10; }
  .form-wrap .alert {
    @apply w-full; }

.alert-success {
  @apply border-green-500; }

.alert-info {
  @apply border-blue-400; }

.alert-warning {
  @apply border-orange-500; }

.alert-danger {
  @apply border-red-500; }

.content-img-center {
  @apply block mx-auto my-4; }

.content-img-left {
  @apply float-left my-4 mr-4; }

.content-img-right {
  @apply float-right my-4 ml-4; }

[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea {
  @apply w-full p-1 border border-gray-500 rounded-sm bg-gray-100; }

textarea {
  @apply align-top; }

select {
  @apply w-full p-1 border border-gray-500 rounded-sm bg-gray-100;
  height: 2.14rem; }

[type=radio], [type=checkbox] {
  @apply mr-1; }

[type=radio] {
  @apply relative;
  top: -1px; }

[type=submit], [type=image], label, select {
  @apply cursor-pointer; }

[type=file] {
  width: 100%; }

.form-wrap {
  @apply w-11/12 max-w-2xl mx-auto my-4 p-4 rounded-sm shadow-md; }

.field-wrap {
  @apply mb-4; }
  .field-wrap label {
    @apply block mb-1; }

.field-wrap-checkbox {
  @apply flex items-center mb-4; }
  .field-wrap-checkbox label {
    @apply inline-block mr-4 mb-0; }

.field-wrap-radio {
  @apply mb-1; }

.field-wrap-radios, .field-wrap-checkboxes {
  @apply mb-4; }
  .field-wrap-radios label, .field-wrap-checkboxes label {
    @apply inline-block mr-4 mb-0; }
  .field-wrap-radios legend, .field-wrap-checkboxes legend {
    @apply mb-1; }
  .field-wrap-radios .field-wrap-checkbox, .field-wrap-checkboxes .field-wrap-checkbox {
    @apply mb-1; }

.field-errors {
  @apply mb-1 pl-0 list-none; }
  .field-errors li {
    @apply mb-1 p-2 bg-gray-200 border-l-10 border-red-700; }

.field-wrap-readonly {
  @apply relative; }
  .field-wrap-readonly input {
    @apply opacity-50; }
  .field-wrap-readonly:after {
    @apply absolute right-0 text-xs opacity-50;
    top: 0.5em;
    content: "read-only"; }

.field-help {
  @apply text-xs italic text-gray-600 leading-tight;
  padding-left: 0.1em; }

.form-action {
  @apply text-sm ml-4; }

.md-enter-active,
.md-leave-active {
  transition: all 0.5s; }

.md-enter,
.md-leave-active {
  opacity: 0;
  transform: translateY(24px); }

button, .button {
  @apply px-4 py-2 text-white bg-blue-primary font-thin shadow-md rounded-sm;
  transition: all 0.35s ease; }
  button:hover, .button:hover {
    @apply shadow-lg bg-blue-700; }

a.button {
  @apply inline-block no-underline; }
  a.button:hover {
    @apply text-white; }

.header-nav_item {
  @apply mb-3; }

@screen md {
  .header-nav_item {
    @apply mr-10 mb-0; }
    .header-nav_item:last-of-type {
      @apply mr-0; } }
  .header-nav_item a {
    @apply text-gray-700 no-underline; }

.subnav-item:last-of-type {
  @apply mb-0; }

@screen md {
  .subnav-item:first-of-type {
    @apply mr-3; }
    .subnav-item:first-of-type:after {
      @apply pl-3;
      content: " | "; } }

.gallery-list {
  @apply max-w-11/12 mx-auto p-0 list-none; }

.gallery-item {
  @apply relative flex items-end max-w-3xl max-h-auto mx-auto my-24; }

@screen lg {
  .gallery-item {
    @apply my-40; } }
  .gallery-item:first-of-type {
    @apply mt-0; }
  .gallery-item:last-of-type {
    @apply mb-0; }
  .gallery-item img {
    @apply w-auto h-auto; }

.gallery_image-info_wrap {
  @apply flex tracking-wider pl-2; }

.gallery_image-title {
  @apply text-base mt-0 mb-1; }

.gallery_image-information {
  @apply hidden bg-white-transparent; }

.gallery_image-information-display {
  @apply block text-sm mt-4;
  max-width: 70%; }
  .gallery_image-information-display p:first-of-type {
    margin-top: 0; }

@screen lg {
  .gallery_image-information-display {
    @apply max-w-full mt-0 ml-4; } }

@screen xl {
  .gallery_image-information-display {
    @apply ml-auto; } }

@screen lg {
  .gallery_img-flex_wrap {
    @apply flex flex-wrap w-full mt-4; } }

@screen lg {
  .gallery_img-info {
    @apply w-1/2; } }

.gallery-info_list {
  @apply list-none p-0; }

.icon-info {
  @apply hidden w-full mr-4 no-underline; }

@screen xl {
  .icon-info {
    @apply mr-0;
    width: 74%; } }

.icon-info svg {
  @apply absolute w-6 h-6 text-blue-primary fill-current;
  top: 0.75rem;
  left: -2.25rem; }

.gallery-return_icon {
  @apply text-right no-underline;
  transition: all 0.25s ease; }
  .gallery-return_icon svg {
    width: 1.5em;
    height: 1.5em;
    @apply text-blue-primary fill-current; }
  .gallery-return_icon:hover svg {
    @apply text-blue-600; }

.gallery-city {
  @apply text-blue-primary; }

.gallery-sold {
  @apply text-red-500; }

.display {
  display: block; }

.z-index {
  z-index: -1; }

@tailwind utilities;
