.header-nav_item {
    @apply mb-3;
    @screen md {
        @apply mr-10 mb-0;
        &:last-of-type {
            @apply mr-0;
        }
    }


    a {
        @apply text-gray-700 no-underline;
    }
}