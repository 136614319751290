.gallery-list {
    @apply max-w-11/12 mx-auto p-0 list-none;
}

.gallery-item {
    @apply relative flex items-end max-w-3xl max-h-auto mx-auto my-24;

    @screen lg {
        @apply my-40;
    }
    &:first-of-type {
        @apply mt-0;
    }
    &:last-of-type {
        @apply mb-0;
    }
    img {
       @apply w-auto h-auto;
    }
}

.gallery_image-info_wrap {
    @apply flex tracking-wider pl-2;
}

.gallery_image-title {
    @apply text-base mt-0 mb-1;
}

.gallery_image-information {
    @apply hidden bg-white-transparent;

}

.gallery_image-information-display {
    @apply block text-sm mt-4;
    max-width: 70%;
    //min-height: 10.25em;

    p:first-of-type {
        margin-top: 0;
    }

    @screen lg {
       @apply max-w-full mt-0 ml-4;
    }

    @screen xl {
        @apply ml-auto;
    }
}

.gallery_img-flex_wrap {
    @screen lg {
        @apply flex flex-wrap w-full mt-4;
    }
}

.gallery_img-info {
    @screen lg {
        @apply w-1/2;
    }
}

.gallery-info_list {
    @apply list-none p-0;
}

.icon-info {
    @apply hidden w-full mr-4 no-underline;

    @screen xl {
        @apply mr-0;
        width: 74%;
    }
}

.icon-info svg {
    @apply absolute w-6 h-6 text-blue-primary fill-current;
    top: 0.75rem;
    left: -2.25rem;
}

.gallery-return_icon {
    @apply text-right no-underline;
    transition: all 0.25s ease;
    svg {
        width: 1.5em;
        height: 1.5em;
        @apply text-blue-primary fill-current;
    }
    &:hover svg {
        @apply text-blue-600;
    }
}

.gallery-city {
    @apply text-blue-primary;
}

.gallery-sold {
    @apply text-red-500;
}

.display {
    display: block;
}

.z-index {
    z-index: -1;
}
