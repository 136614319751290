button, .button {
    @apply px-4 py-2 text-white bg-blue-primary font-thin shadow-md rounded-sm;
    transition: all 0.35s ease;
    &:hover {
        @apply shadow-lg bg-blue-700;
    }
}

a.button {
    @apply inline-block no-underline;
    &:hover {
        @apply text-white;
    }
}