.subnav-item:last-of-type {
    @apply mb-0;
}

.subnav-item:first-of-type {
    @screen md {
        @apply mr-3;

        &:after {
            @apply pl-3;
            content: " | ";
        }
    }

}
